@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Candal&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@600&display=swap');

html, body, .root {
  max-width: 100vw;
  min-width: 100vw;
  margin:0;
  padding:0;
}

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-black{
  background-color: black !important;
}

.nav-link:hover{
  color: #b89d06 !important;
}

.nav-head{
  font-size: 1.5rem;
  font-weight: 600;
  text-transform: uppercase;
}

.text-gold{
  color: #b89d06 !important;
}

.font-title{
  font-size: 2.5rem;
  line-height: 1.35em;
}

.main-body{
  min-height: 96vh;
}

.img-black {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.hero-container {
  /*background: url('./images/apep_enfants_cover.jpeg') center center/cover no-repeat; */
  height: 70vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}
.carousel .slide .legend-text {
  transition: all .5s ease-in-out;
  position: absolute;
  bottom: 150px;
  left: 50%;
  margin-left: -45%;
  width: 90%;
  border-radius: 10px;
  background: #000;
  color: #fff;
  padding: 10px;
  font-size: 1.5em;
  font-family: 'Dancing Script', cursive;
  text-align: center;
  opacity: .75;
  transition: opacity .35s ease-in-out;
}

.btn-absolute {
  position: absolute;
  bottom: 10px;
  left: 50%;
  margin-left: -40%;
  width: 80%;
  padding: 10px;
  text-align: center;
  opacity: .75;
}

.hero-container > h1 {
  color: #fff;
  font-size: 40px;
  margin-top: 0px;
  text-transform: uppercase;
}

.hero-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 30px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
  text-transform: capitalize;
}

.hero-btns {
  margin-top: 32px;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

.font-light{
  font-weight: 200;
}

.carousel-root{
  padding-top: 13vh;
  height: 80vh;
}

.carousel.carousel-slider , .slider-wrapper.axis-horizontal, ul.slider.animated {
  height: 100%;
}

.home-title{
  font-family: 'Comfortaa', cursive;
  font-size: 2.25rem;
  font-weight: 800;
}

.home-section-title{
  font-family: 'Candal', sans-serif;
  font-size: 3.5rem;
  font-weight: 800;
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 40px;
    margin-top: 0px;
  }
}

@media screen and (max-width: 768px) {
  html, body, .root {
    max-width: 100vw;
    min-width: 100vw;
    margin:0;
    padding:0;
  }
  .nav-link {
    display: block;
    padding: .1rem 1rem;
  }

  .hero-container{
    height: 50vh;
  }

  .hero-container > h1 {
    font-size: 30px;
    margin-top: 0px;
  }

  .hero-container > p {
    font-size: 20px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
  .carousel-root{
    padding-top: 17vh;
    height: 50vh;
  }
  .carousel .slide .legend-text {
    bottom: 5vh;
    font-size: 1em;
  }
  .btn-absolute {
    width: 80%;
  }
  .home-section-title{
    font-size: 2.7rem;
  }

  .home-title{
    font-size: 2.0rem;
  }
  .card-body{
    padding: 0;
  }
}